import React from 'react';
import { useAudioOn } from 'src/context/audio-on';
import { unmute } from './unmute';

declare global {
	interface Window {
		AudioContext: AudioContext;
		webkitAudioContext: AudioContext;
	}
}

const AudioContext = window.AudioContext || window.webkitAudioContext;

type TBuffers = { [key: string]: AudioBuffer };
type TSources = AudioBufferSourceNode[];

const soundEffects = {
	beep: '/sounds/beep.mp3',
	bootClose: '/sounds/boot-close-clunk.mp3',
	buttonPress: '/sounds/button-press-bleep.mp3',
	dropItem: '/sounds/drop-item-clunk.mp3',
	home: '/sounds/home-screen-bg-music.mp3',
	level: '/sounds/level-1-bg-music-tbc.mp3',
	levelFail: '/sounds/level-failure.mp3',
	levelSuccess: '/sounds/level-success.mp3',
	uni: '/sounds/moving-to-uni-bg-music-tbc.mp3',
	pickItem: '/sounds/pick-up-item-swish.mp3',
	inLaws: '/sounds/visiting-inlaws-bg-music-tbc.mp3',
};

const backgroundLoops = ['home', 'level', 'uni', 'inLaws'];

let context: AudioContext | undefined;
let isLoading = false;
let hasLoaded = false;
const buffers: TBuffers = {};
const sources: TSources = []; // currently playing sounds
let backgroundMusicKey = 'home';
let backgroundMusicSource: AudioBufferSourceNode | undefined;

export function setBackgroundMusic(key: string): void {
	backgroundMusicKey = key;
	startBackgroundMusic();
}

export function useBackgroundMusic(key: string): void {
	const [audioOn] = useAudioOn();
	React.useEffect(() => {
		if (audioOn && key !== backgroundMusicKey) {
			setBackgroundMusic(key);
		}
	}, [key, audioOn]);
}

export function pauseBackgroundMusic(pause: boolean): void {
	if (backgroundMusicSource) {
		if (pause) {
			backgroundMusicSource.playbackRate.value = 0;
		} else {
			backgroundMusicSource.playbackRate.value = 1;
		}
	}
}

export function restartBackgroundMusic(): void {
	if (backgroundMusicSource) {
		startBackgroundMusic();
	}
}

export function startBackgroundMusic(): void {
	if (context && hasLoaded) {
		backgroundMusicSource?.stop();
		if (buffers[backgroundMusicKey]) {
			backgroundMusicSource = context.createBufferSource();
			backgroundMusicSource.buffer = buffers[backgroundMusicKey];
			backgroundMusicSource.connect(context.destination);
			if (backgroundLoops.includes(backgroundMusicKey)) {
				backgroundMusicSource.loop = true;
			}
			backgroundMusicSource.start();
		}
	}
}

export function loadAudio(onComplete?: () => void): void {
	if (!hasLoaded && !isLoading) {
		isLoading = true;
		context = new AudioContext();
		unmute(context, false, false);
		let loaded = 0;
		Object.entries(soundEffects).forEach(([key, url]) => {
			fetch(url)
				.then((res) => res.arrayBuffer())
				.then((arrayBuffer) => context?.decodeAudioData(arrayBuffer))
				.then((audioBuffer) => {
					if (audioBuffer) {
						buffers[key] = audioBuffer;
					}
					loaded++;
					if (loaded >= Object.keys(soundEffects).length) {
						hasLoaded = true;
						onComplete && onComplete();
					}
				});
		});
	}
}

export function useLoadAudio(): void {
	const [audioOn] = useAudioOn();
	const [hasLoaded, setHasLoaded] = React.useState(false);
	const [hasInitialisedMusic, setHasInitialisedMusic] = React.useState(false);

	// load the audio when the app is initialised
	React.useEffect(() => {
		loadAudio(() => {
			// trigger a re-render when the audio has finished loading
			setHasLoaded(true);
		});
	}, []);

	React.useEffect(() => {
		// start the background music when:
		// 1. the audio files have finished loading
		// 2. the audio is enabled by the user
		// 3. the background music has not already been started
		if (hasLoaded && audioOn && !hasInitialisedMusic) {
			setHasInitialisedMusic(true);
			startBackgroundMusic();
		}
	}, [audioOn, hasLoaded, hasInitialisedMusic]);
}

export function usePlayAudio(): (key: string) => void {
	const [audioOn] = useAudioOn();
	const fn = React.useCallback(
		(key: string) => {
			if (audioOn && hasLoaded && context && buffers[key]) {
				const source = context.createBufferSource();
				source.buffer = buffers[key];
				source.connect(context.destination);
				source.start();
				sources.push(source);
			}
		},
		[audioOn],
	);
	return fn;
}

export function stopAllAudio(): void {
	sources.forEach((source) => {
		source.stop();
	});
	backgroundMusicSource?.stop();
}
