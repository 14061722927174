import React from 'react';
import { AboutScreen } from './components/screens/about';
import { CompleteScreen } from './components/screens/complete';
import { GameScreen } from './components/screens/game';
import { HomeScreen } from './components/screens/home';
import { LevelEditorScreen } from './components/screens/level-editor';
import { LevelSelectScreen } from './components/screens/level-select';
import { TutorialScreen } from './components/screens/tutorial';
import { useActiveLevel } from './context/active-level';
import { EScreen, useScreen } from './context/screen';
import { useLoadAudio } from './utils/audio';

const App: React.FC = () => {
	useLoadAudio();
	const [, setActiveLevel] = useActiveLevel();
	const [screen, setScreen] = useScreen();

	return (
		<>
			{screen === EScreen.Home && <HomeScreen />}
			{screen === EScreen.About && <AboutScreen />}
			{screen === EScreen.LevelEditor && <LevelEditorScreen />}
			{screen === EScreen.LevelSelect && (
				<LevelSelectScreen
					onLevelSelect={(levelId) => {
						setActiveLevel(levelId);
						setScreen(EScreen.Game);
					}}
				/>
			)}
			{screen === EScreen.Tutorial && <TutorialScreen />}
			{screen === EScreen.Game && <GameScreen />}
			{screen === EScreen.Complete && <CompleteScreen />}
		</>
	);
};

export default App;
