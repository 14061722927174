import React from 'react';
import { startBackgroundMusic, stopAllAudio } from 'src/utils/audio';
import { getLocalStorage, setLocalStorage } from 'src/utils/local-storage';

type TAudioOnContext = [boolean, (value: boolean) => void];

const Context = React.createContext<TAudioOnContext>([
	false,
	() => {
		return undefined;
	},
]);

export const AudioOnProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [value, setValue] = React.useState(
		getLocalStorage('audioon') === 'true',
	);
	const setValueLocal = (value: boolean): void => {
		if (value) {
			startBackgroundMusic();
		} else {
			stopAllAudio();
		}
		setLocalStorage('audioon', value);
		setValue(value);
	};
	return (
		<Context.Provider value={[value, setValueLocal]}>
			{children}
		</Context.Provider>
	);
};

export const useAudioOn = (): TAudioOnContext => {
	return React.useContext(Context);
};
