import React from 'react';

export enum EScreen {
	Home = 'Home',
	About = 'About',
	LevelEditor = 'LevelEditor',
	LevelSelect = 'LevelSelect',
	Tutorial = 'Tutorial',
	Game = 'Game',
	Complete = 'Complete',
}

type TScreenContext = [EScreen, (value: EScreen) => void];

const Context = React.createContext<TScreenContext>([
	EScreen.Home,
	() => {
		return undefined;
	},
]);

export const ScreenProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const value = React.useState(EScreen.Home);
	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useScreen = (): TScreenContext => {
	return React.useContext(Context);
};
