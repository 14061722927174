import React from 'react';
import { MobileScreenWrapper } from 'src/components/atoms/mobile-screen-wrapper';
import { EScreen, useScreen } from 'src/context/screen';
import { EIcon, IconButton } from 'src/components/atoms/icon-button';
import { StarRating } from 'src/components/atoms/star-rating';
import { Titlebar } from 'src/components/atoms/titlebar';
import { useLevelProgress } from 'src/context/level-progress';
import { useBackgroundMusic } from 'src/utils/audio';
import data from '../game/data';
import styles from './index.module.scss';
import { themeImages } from '../game/data/images';

type TLevelSelect = {
	onLevelSelect: (levelId: string) => void;
};

export const LevelSelectScreen: React.FC<TLevelSelect> = ({
	onLevelSelect,
}) => {
	useBackgroundMusic('home');
	const [, setScreen] = useScreen();
	const [levelProgress] = useLevelProgress();

	const isAvailableToPlay = React.useCallback(
		(levelId: string) => {
			// already complete
			if (levelProgress[levelId]) {
				return true;
			}
			// find previous level in group
			let previousLevelId = '';
			data.levelOrder.forEach((levelOrder) => {
				levelOrder.levels.forEach((checkLevelId, i) => {
					if (levelId === checkLevelId) {
						previousLevelId = levelOrder.levels[i - 1] || '';
					}
				});
			});
			// check if previous level is complete
			if (previousLevelId) {
				return !!levelProgress[previousLevelId];
			}
			return true;
		},
		[levelProgress],
	);

	return (
		<MobileScreenWrapper className={styles.levelSelect} scrollable>
			<Titlebar>
				<div className={styles.title}>
					<IconButton
						icon={EIcon.Home}
						onClick={() => setScreen(EScreen.Home)}
					/>
					<h1>Level Select</h1>
					<div />
				</div>
			</Titlebar>

			<div className={styles.content}>
				{data.levelOrder.map((levelGroup, i) => {
					const { color } = data.themes[levelGroup.theme];
					return (
						<div className={styles.levelWrapper} key={i}>
							<h2
								className={styles.levelTitle}
								style={{ backgroundColor: color }}
							>
								{levelGroup.title}
							</h2>

							<div
								className={styles.levelGrid}
								style={{
									backgroundImage: `url(${
										themeImages[levelGroup.theme]
									})`,
								}}
							>
								{levelGroup.levels.map((levelId, i) => {
									const progress = levelProgress[levelId];

									return (
										<div
											key={i}
											className={styles.levelItem}
										>
											<button
												onClick={() =>
													onLevelSelect(levelId)
												}
												disabled={
													!isAvailableToPlay(levelId)
												}
											>
												{i + 1}

												{!!progress && (
													<StarRating
														rating={progress.stars}
														className={
															styles.itemStars
														}
													/>
												)}
											</button>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		</MobileScreenWrapper>
	);
};
