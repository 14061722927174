import classNames from 'classnames';
import React from 'react';
import { AudioOnButton } from 'src/components/atoms/audio-on-button';
import { Button } from 'src/components/atoms/button';
import { IconButton, EIcon } from 'src/components/atoms/icon-button';
import { MobileScreenWrapper } from 'src/components/atoms/mobile-screen-wrapper';
import { Modal } from 'src/components/atoms/modal';
import { Titlebar } from 'src/components/atoms/titlebar';
import data from 'src/components/screens/game/data';
import { useActiveLevel } from 'src/context/active-level';
import { useAudioOn } from 'src/context/audio-on';
import {
	TLevelPlayed,
	useSetIndividualLevelProgress,
} from 'src/context/level-progress';
import { EScreen, useScreen } from 'src/context/screen';
import { pauseBackgroundMusic, setBackgroundMusic } from 'src/utils/audio';
import { formatTime } from 'src/utils/format-time';
import { GameEngine } from './engine';
import { createGame, getGameScores, packPercentage } from './helpers';
import styles from './index.module.scss';
import { GameMenu } from './menu';
import { LostGame, WonGame } from './scores';

export const GameScreen: React.FC = () => {
	const [, setScreen] = useScreen();
	const [levelId] = useActiveLevel();

	const level = data.levels[levelId];
	const hasTimer = React.useMemo(
		() => level.timeLimit !== undefined,
		[level.timeLimit],
	);

	const [pauseModal, setPauseModal] = React.useState(false);
	const [wonGame, setWonGame] = React.useState(false);
	const [lostGame, setLostGame] = React.useState(false);

	// can the player interact with the game
	const isActive = React.useMemo(
		() => !wonGame && !lostGame && !pauseModal,
		[wonGame, lostGame, pauseModal],
	);

	// set music
	const [audioOn] = useAudioOn();
	React.useEffect(() => {
		if (audioOn) {
			setBackgroundMusic('level');
		}
	}, [audioOn, level]);
	React.useEffect(() => {
		pauseBackgroundMusic(pauseModal);
	}, [pauseModal]);

	// handle game state
	const [game, setGame] = React.useState(() => createGame(level, levelId));

	const startGame = React.useCallback((): void => {
		setGame(createGame(level, levelId));
		setPauseModal(false);
		setWonGame(false);
		setLostGame(false);
		setTimer(level.timeLimit || 0);
	}, [level, levelId]);

	const restart = React.useCallback(() => {
		startGame();
		if (audioOn) {
			setBackgroundMusic('level');
		}
	}, [startGame, audioOn]);

	// restart game when level changes
	React.useEffect(() => {
		startGame();
	}, [startGame, levelId]);

	// timer
	const [timer, setTimer] = React.useState(level.timeLimit || 0);
	React.useEffect(() => {
		if (hasTimer) {
			const clock = setInterval(() => {
				if (isActive) {
					setTimer((t) => Math.max(0, t - 1));
				}
			}, 1000);
			return () => {
				clearInterval(clock);
			};
		}
	}, [isActive, hasTimer]);

	const highlightTimer = timer < 11 && timer > 0;
	const lostTimer = lostGame && timer === 0;

	// finishing the game
	const [currentScores, setCurrentScores] = React.useState<
		TLevelPlayed | undefined
	>();
	const setLevelProgress = useSetIndividualLevelProgress();
	const finishGame = React.useCallback((): void => {
		if (game) {
			setPauseModal(false);
			const scores = getGameScores(game, timer);
			if (scores) {
				setWonGame(true);
				setLevelProgress(levelId, scores);
				setCurrentScores(scores);
			} else {
				setLostGame(true);
			}
		}
	}, [game, levelId, setLevelProgress, timer]);

	// finish the game when timer runs out
	React.useEffect(() => {
		if (hasTimer && timer <= 0 && !wonGame && !lostGame) {
			finishGame();
		}
	}, [timer, finishGame, wonGame, lostGame, hasTimer]);

	// level requirements
	const requirementsMet = React.useMemo(
		() => game?.requiredItems.length === 0,
		[game],
	);

	// finish the game if it's perfect
	React.useEffect(() => {
		if (
			game &&
			isActive &&
			packPercentage(game) === 100 &&
			requirementsMet
		) {
			finishGame();
		}
	}, [game, isActive, finishGame, requirementsMet]);

	// figure out game title from theme
	const [themeId, setTheme] = React.useState('');
	const titleFromTheme = React.useMemo(() => {
		let title = '';
		data.levelOrder.some((levelGroup) => {
			return levelGroup.levels.some((checkLevelId, i) => {
				if (levelId === checkLevelId) {
					title = `${levelGroup.title} ${i + 1}`;
					setTheme(levelGroup.theme);
					return true;
				}
			});
		});
		return title;
	}, [levelId]);

	return (
		<MobileScreenWrapper className={styles.bg}>
			<Titlebar>
				<div className={styles.title}>
					{wonGame || lostGame ? (
						<IconButton
							icon={EIcon.Home}
							onClick={() => setScreen(EScreen.Home)}
						/>
					) : (
						<IconButton
							icon={EIcon.Pause}
							onClick={() => setPauseModal(!pauseModal)}
						/>
					)}
					<div className={styles.titleText}>
						<h1>{titleFromTheme}</h1>
						{hasTimer && (
							<div
								className={classNames(
									styles.titleTimer,
									highlightTimer && styles.titleTimerRed,
									lostTimer && styles.titleTimerLost,
								)}
							>
								{formatTime(timer || 0)}
							</div>
						)}
					</div>
					<AudioOnButton />
				</div>
			</Titlebar>
			<div className={styles.mainWrapper}>
				{game && (
					<GameEngine
						game={game}
						setGame={setGame}
						isActive={isActive}
					/>
				)}
				<section className={styles.requirements}>
					{requirementsMet ? (
						<Button
							className={styles.pointerEvents}
							onClick={() => finishGame()}
							sound="bootClose"
						>
							Close boot
						</Button>
					) : (
						<div className={styles.requirementsText}>
							Required items remaining:{' '}
							<b>{game?.requiredItems.length}</b>
						</div>
					)}
				</section>
				<Modal active={pauseModal}>
					<GameMenu
						onCancel={() => setPauseModal(false)}
						onRestart={restart}
					/>
				</Modal>
				<Modal active={lostGame}>
					<LostGame restartGame={restart} themeId={themeId} />
				</Modal>
				<Modal active={wonGame} className={styles.wonGame}>
					{game && (
						<WonGame
							levelId={levelId}
							restartGame={restart}
							game={game}
							timer={timer}
							currentScores={currentScores}
							themeId={themeId}
						/>
					)}
				</Modal>
			</div>
		</MobileScreenWrapper>
	);
};
