import React from 'react';
import { Button, ButtonList, ButtonVariant } from 'src/components/atoms/button';
import { EScreen, useScreen } from 'src/context/screen';
import snowflakeHourglass from 'src/images/snowflake-hourglass.png';
import styles from './index.module.scss';

type TGameMenu = {
	onCancel: () => void;
	onRestart: () => void;
};

export const GameMenu: React.FC<TGameMenu> = ({ onCancel, onRestart }) => {
	const [, setScreen] = useScreen();
	return (
		<>
			<img src={snowflakeHourglass} alt="" className={styles.hourglass} />
			<h2>You froze time</h2>
			<p>
				Take a break, even the most experienced boot packers sometimes
				need a minute or two...
			</p>
			<ButtonList>
				<Button variant={ButtonVariant.Red} onClick={() => onCancel()}>
					Continue
				</Button>
				<Button
					variant={ButtonVariant.White}
					onClick={() => onRestart()}
				>
					Restart Game
				</Button>
				<Button
					variant={ButtonVariant.Transparent}
					onClick={() => setScreen(EScreen.Home)}
				>
					Return home
				</Button>
			</ButtonList>
		</>
	);
};
