import React from 'react';
import { ActiveLevelProvider } from './active-level';
import { AudioOnProvider } from './audio-on';
import { LevelProgressProvider } from './level-progress';
import { ScreenProvider } from './screen';

export const ContextProviders: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	return (
		<AudioOnProvider>
			<ScreenProvider>
				<ActiveLevelProvider>
					<LevelProgressProvider>{children}</LevelProgressProvider>
				</ActiveLevelProvider>
			</ScreenProvider>
		</AudioOnProvider>
	);
};
