import classNames from 'classnames';
import React from 'react';
import data from 'src/components/screens/game/data';
import { shapeRotations } from 'src/components/screens/game/data/computed';
import { itemImages } from './data/images';
import styles from './index.module.scss';
import { DEBUG_SHAPES, TBoardItem } from './types';

/**
 * Draw board background
 */

export const DrawBoardBackground: React.FC<{
	board: number[][];
	gridSize: number;
}> = ({ board, gridSize }) => (
	<div className={styles.boardBackground}>
		{board.map((row, rowIndex) => (
			<div key={rowIndex} className={styles.boardBackgroundRow}>
				{row.map((value, colIndex) => (
					<div
						key={colIndex}
						style={{
							width: gridSize,
							height: gridSize,
						}}
					>
						<div
							className={
								value === 0 ? styles.boardBackgroundSquare : ''
							}
						></div>
					</div>
				))}
			</div>
		))}
	</div>
);

/**
 * Draw a game item
 */

export const DrawItemGrid: React.FC<{
	itemId: string;
	rotation?: number;
	color?: 'Black' | 'Green' | 'Red';
	fill?: 'White' | 'Green' | 'Red';
	size: number;
}> = ({ itemId, rotation = 0, color = 'Black', fill = false, size }) => {
	const itemSpec = data.items[itemId];
	if (!itemSpec) return null;
	const rotatedShape = shapeRotations[itemId][rotation];
	return (
		<div
			style={{
				width: size * rotatedShape[0].length,
				height: size * rotatedShape.length,
			}}
		>
			{rotatedShape.map((row, rowIndex) => (
				<div key={rowIndex} className={styles.itemGridRow}>
					{row.map((value, colIndex) => {
						const top =
							rotatedShape[rowIndex - 1]?.[colIndex] !== 1;
						const bottom =
							rotatedShape[rowIndex + 1]?.[colIndex] !== 1;
						const left = rotatedShape[rowIndex][colIndex - 1] !== 1;
						const right =
							rotatedShape[rowIndex][colIndex + 1] !== 1;
						return (
							<div
								key={colIndex}
								className={classNames(
									styles[`itemShape${color}`],
									styles.itemGridCell,
									value === 1 &&
										fill &&
										styles[`itemFill${fill}`],
								)}
								style={{
									width: size,
									height: size,
									borderTopWidth: value === 1 && top ? 2 : 0,
									borderBottomWidth:
										value === 1 && bottom ? 2 : 0,
									borderLeftWidth:
										value === 1 && left ? 2 : 0,
									borderRightWidth:
										value === 1 && right ? 2 : 0,
								}}
							></div>
						);
					})}
				</div>
			))}
		</div>
	);
};

/**
 * Draw a game item
 */

export const DrawItemImage: React.FC<{
	itemId: string;
	rotation?: number;
	size: number;
	center?: boolean;
}> = ({ itemId, rotation = 0, size, center = false }) => {
	const itemSpec = data.items[itemId];
	if (!itemSpec) return null;

	let transform = '';
	let transformOrigin = '';
	if (center) {
		transform = `rotate(${rotation * 90}deg)`;
	} else {
		if (rotation === 1) {
			transformOrigin = 'top left';
			transform = 'rotate(90deg) translateY(-100%)';
		} else if (rotation === 2) {
			transform = 'rotate(180deg)';
		} else if (rotation === 3) {
			transformOrigin = 'top left';
			transform = 'rotate(270deg) translateX(-100%)';
		}
	}
	return (
		<div>
			<div
				style={{
					width: size * itemSpec.shape[0].length,
					transform,
					transformOrigin,
				}}
			>
				{DEBUG_SHAPES || !itemSpec.image ? (
					<DrawItemGrid
						itemId={itemId}
						rotation={0}
						fill="White"
						size={size}
					/>
				) : (
					<img src={itemImages[itemId]} alt="" />
				)}
			</div>
		</div>
	);
};

/**
 * Draw game items on the board
 */

export const DrawBoardItems: React.FC<{
	items: TBoardItem[];
	gridSize: number;
}> = ({ items, gridSize }) => (
	<div className={styles.boardItems}>
		{items.map((placedItem, i) => {
			const [x, y] = placedItem.location;
			return (
				<div
					key={i}
					style={{
						left: gridSize * x,
						top: gridSize * y,
					}}
				>
					<DrawItemImage
						itemId={placedItem.itemId}
						rotation={placedItem.rotation}
						size={gridSize}
					/>
				</div>
			);
		})}
	</div>
);
