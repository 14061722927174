import React from 'react';
import copy from 'src/images/copy.png';
import styles from './index.module.scss';

type TCopyToClipboard = {
	text: string;
};

export const CopyToClipboard: React.FC<TCopyToClipboard> = ({ text }) => {
	const [copied, setCopied] = React.useState(false);
	const onCopy = React.useCallback(() => {
		copyWithFallback(text);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 2000);
	}, [text]);
	return (
		<>
			{copied && <div className={styles.popup}>Copied to clipboard</div>}
			<button className={styles.shareIconWrap} onClick={onCopy}>
				<img
					className={styles.shareIcon}
					src={copy}
					alt="Copy your score to clipboard"
				/>
			</button>
		</>
	);
};

function copyWithFallback(text: string): void {
	if (!navigator.clipboard) {
		const textArea = document.createElement('textarea');
		textArea.value = text;
		textArea.style.top = '0';
		textArea.style.left = '0';
		textArea.style.position = 'fixed';
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);
	} else {
		navigator.clipboard.writeText(text);
	}
}
