import classNames from 'classnames';
import React from 'react';
import { Button, ButtonVariant } from 'src/components/atoms/button';
import { CopyToClipboard } from 'src/components/atoms/copy-to-clipboard';
import { StarRating } from 'src/components/atoms/star-rating';
import { useActiveLevel } from 'src/context/active-level';
import { TLevelPlayed, useLevelProgress } from 'src/context/level-progress';
import { EScreen, useScreen } from 'src/context/screen';
import { useBackgroundMusic } from 'src/utils/audio';
import { formatTime } from 'src/utils/format-time';
import hourglass from 'src/images/icons/hourglass--orange.png';
import data from './data';
import { flatLevelIds } from './data/computed';
import { getCompletedEmojiGameGrid, packPercentage } from './helpers';
import styles from './index.module.scss';
import { TGameInProgress } from './types';

type TWonGame = {
	levelId: string;
	restartGame: (newLevelId?: string) => void;
	game: TGameInProgress;
	timer: number;
	currentScores?: TLevelPlayed;
	themeId: string;
};

export const WonGame: React.FC<TWonGame> = ({
	levelId,
	restartGame,
	game,
	timer,
	currentScores,
	themeId,
}) => {
	useBackgroundMusic('levelSuccess');

	let performanceWord;
	let comparisonPercentage;
	let scoreRank;
	const [, setScreen] = useScreen();
	const [levelProgress] = useLevelProgress();
	const [, setActiveLevel] = useActiveLevel();
	const record = levelProgress[levelId];

	const shareString = React.useMemo(() => {
		const emojiGrid = getCompletedEmojiGameGrid(game);
		let emojiScoreStr = '';

		let emojiCode = 0;
		if (themeId === 'beach') {
			emojiCode = 127958;
		} else if (themeId === 'camping') {
			emojiCode = 127957;
		} else if (themeId === 'uni') {
			emojiCode = 128218;
		} else if (themeId === 'inLaws') {
			emojiCode = 127984;
		}

		const timeStr = String.fromCodePoint(9201) + ': ' + formatTime(timer);

		const percentStr =
			String.fromCodePoint(128170) + ': ' + packPercentage(game) + '%';

		let scoreStr = String.fromCodePoint(11088) + ': ';
		if (currentScores?.score) {
			scoreStr += currentScores.score;
		}

		const levelGroup = data.levelOrder.find((group) =>
			group.levels.includes(levelId),
		);
		const levelNumber =
			(levelGroup?.levels.findIndex((id) => id === levelId) || 0) + 1;
		const levelStr = `${String.fromCodePoint(emojiCode)}: ${levelNumber}/${
			levelGroup?.levels.length
		}`;

		emojiGrid.forEach((row) => {
			emojiScoreStr += row.join('') + '\n';
		});

		/**
		 *  This should be refactored if share to Twitter and Facebook are
		 *  added in future as this will need to be a param in the URL
		 */
		const hashtag = '#carbootcarnage';

		const shareStr =
			timeStr +
			'\n' +
			percentStr +
			'\n' +
			scoreStr +
			'\n' +
			levelStr +
			'\n' +
			emojiScoreStr +
			'\n' +
			hashtag;
		return shareStr;
	}, [currentScores, game, themeId, timer]);

	const onNextLevel = React.useCallback(() => {
		const isLastLevel = levelId === flatLevelIds.slice(-1)[0];
		if (isLastLevel) {
			const hasCompletedGame = flatLevelIds.every(
				(checkLevelId) => !!levelProgress[checkLevelId],
			);
			if (hasCompletedGame) {
				setScreen(EScreen.Complete);
			} else {
				const nextUncompletedLevel = flatLevelIds.find(
					(checkLevelId) => !levelProgress[checkLevelId],
				);
				if (nextUncompletedLevel) {
					setActiveLevel(nextUncompletedLevel);
				} else {
					setScreen(EScreen.Complete);
				}
			}
		} else {
			const currentLevelIndex = flatLevelIds.findIndex(
				(checkLevelId) => checkLevelId === levelId,
			);
			const nextLevelId = flatLevelIds[currentLevelIndex + 1];
			setActiveLevel(nextLevelId);
		}
	}, [levelId, levelProgress, setActiveLevel, setScreen]);

	if (!record) {
		return null;
	}

	if (currentScores?.stars) {
		if (currentScores.stars > 1) {
			scoreRank = 'top';
		} else {
			scoreRank = 'bottom';
		}

		if (currentScores.stars === 0.5) {
			performanceWord = 'Carnage!';
			comparisonPercentage = '20%';
		} else if (currentScores.stars === 1) {
			performanceWord = 'Pants';
			comparisonPercentage = '40%';
		} else if (currentScores.stars === 1.5) {
			performanceWord = 'A bit iffy';
			comparisonPercentage = '50%';
		} else if (currentScores.stars === 2) {
			performanceWord = "That'll do!";
			comparisonPercentage = '30%';
		} else if (currentScores.stars === 2.5) {
			performanceWord = 'Grand';
			comparisonPercentage = '5%';
		} else if (currentScores.stars === 3) {
			performanceWord = 'Perfect!!!';
			comparisonPercentage = '1%';
		}
	}

	return (
		<>
			<div className={styles.wonHeader}>
				<p className={styles.wonScore}>{currentScores?.score}</p>
				<StarRating rating={currentScores?.stars} />
			</div>

			<h2 className={styles.wonTitle}>{performanceWord}</h2>
			<p className={styles.wonScoreComparison}>
				You scored in the {scoreRank}{' '}
				<span>{comparisonPercentage}</span> of players
			</p>
			<div className={styles.wonStats}>
				<p>
					Pack efficiency: <span>{packPercentage(game)}%</span>
				</p>
				<p>
					Time remaining: <span>{formatTime(timer)}</span>
				</p>
				{record.previousScore !== undefined && (
					<p>
						Previous high score:
						<span
							className={classNames(
								currentScores?.score &&
									currentScores?.score >
										record.previousScore &&
									styles.wonHigherScore,
							)}
						>
							{record.previousScore}
						</span>
					</p>
				)}
			</div>

			{currentScores?.stars && currentScores?.stars >= 2 && (
				<div className={styles.shareTop}>
					<h2>Share your score</h2>
					<CopyToClipboard text={shareString} />
				</div>
			)}

			<Button
				variant={ButtonVariant.Red}
				className={!currentScores?.perfectPack ? styles.btnMargin : ''}
				onClick={onNextLevel}
			>
				Next Level
			</Button>
			{!currentScores?.perfectPack && (
				<Button
					variant={ButtonVariant.White}
					onClick={() => restartGame()}
				>
					Replay level
				</Button>
			)}

			{currentScores?.stars && currentScores?.stars < 2 && (
				<div className={styles.shareBottom}>
					<p>Share your score:</p>
					<CopyToClipboard text={shareString} />
				</div>
			)}
		</>
	);
};

type TLostGame = {
	themeId: string;
	restartGame: (newLevelId?: string) => void;
};

export const LostGame: React.FC<TLostGame> = ({ themeId, restartGame }) => {
	const theme = data.themes[themeId];
	useBackgroundMusic('levelFail');

	const title = 'Uh oh! Time’s up!';
	const [, setScreen] = useScreen();
	return (
		<>
			<img className={styles.hourglass} alt="" src={hourglass} />
			<h2 className={styles.lostTitle}>{title}</h2>
			<p className={styles.text}>{theme.failCopy}</p>
			<Button
				className={styles.btnMargin}
				variant={ButtonVariant.Red}
				onClick={() => restartGame()}
			>
				Replay level
			</Button>
			<Button
				variant={ButtonVariant.Transparent}
				onClick={() => setScreen(EScreen.Home)}
			>
				Return home
			</Button>
		</>
	);
};
