import classNames from 'classnames';
import React from 'react';
import { Button, ButtonVariant } from 'src/components/atoms/button';
import data from 'src/components/screens/game/data';
import { shapeRotations } from 'src/components/screens/game/data/computed';
import { usePlayAudio } from 'src/utils/audio';
import {
	DrawBoardBackground,
	DrawBoardItems,
	DrawItemGrid,
	DrawItemImage,
} from './engine-draw';
import {
	checkValidDrop,
	gameStateDroppedItem,
	gameStatePickUpItem,
	gameStateSideEffects,
	getClosestGridReferences,
} from './helpers';
import {
	useGridSize,
	useLongAndShortTap,
	usePreventTouchEventDuplication,
} from './hooks';
import styles from './index.module.scss';
import { TGameInProgress } from './types';

type TGameEngineEvent = {
	onItemDrop?: () => unknown;
	onItemPickupFromBoard?: () => unknown;
	onRotate?: () => unknown;
};

type TGameEngine = {
	game: TGameInProgress;
	setGame: (newGame: TGameInProgress) => void;
	isActive?: boolean;
	events?: TGameEngineEvent;
};

let touchCount = 0;

export const GameEngine: React.FC<TGameEngine> = ({
	game,
	setGame,
	isActive = false,
	events,
}) => {
	const { hasTouched, touch } = usePreventTouchEventDuplication();
	const longAndShortTap = useLongAndShortTap();
	const boardRef = React.useRef<HTMLDivElement>(null);
	const containerRef = React.useRef<HTMLDivElement>(null);
	const level = data.levels[game.levelId];
	const gridSize = useGridSize(boardRef, level);
	const playAudio = usePlayAudio();
	const board = data.boards[level.boardId];

	const boardWidthPercentage = React.useMemo(() => {
		return 100 - Math.max(0, 9 - board[0].length) * 8.88;
	}, [board]);

	/**
	 * Handle the active item
	 */

	const [activeItemIndex, setActiveItemIndex] = React.useState<
		number | undefined
	>();
	const [activeItemOffset, setActiveItemOffset] = React.useState<
		[number, number] | undefined
	>();
	const hasActiveItem = React.useMemo(
		() => activeItemIndex !== undefined,
		[activeItemIndex],
	);
	const activeItem = React.useMemo(() => {
		return activeItemIndex !== undefined
			? game.unusedItems[activeItemIndex]
			: undefined;
	}, [activeItemIndex, game.unusedItems]);

	// drop item if game is no longer active
	React.useEffect(() => {
		setActiveItemIndex(undefined);
	}, [isActive, game.levelId]);

	// handle rotation
	const rotate = React.useCallback(
		(index: number) => {
			const unusedItems = [...game.unusedItems];
			unusedItems[index].rotation = (unusedItems[index].rotation + 1) % 4;
			setGame(gameStateSideEffects(game, { unusedItems }));
			events?.onRotate && events.onRotate();
		},
		[events, game, setGame],
	);

	// rotate when holding an item
	React.useEffect(() => {
		const touchStart = (e: TouchEvent): void => {
			touchCount = e.touches.length;
			if (activeItemIndex !== undefined && e.touches.length === 2) {
				rotate(activeItemIndex);
			}
		};
		const keyDown = (e: KeyboardEvent): void => {
			if (activeItemIndex !== undefined && e.code === 'KeyR') {
				rotate(activeItemIndex);
			}
		};
		document.body.addEventListener('touchstart', touchStart);
		document.body.addEventListener('keydown', keyDown);
		return () => {
			document.body.removeEventListener('touchstart', touchStart);
			document.body.removeEventListener('keydown', keyDown);
		};
	}, [activeItemIndex, rotate]);

	// events for the button row with upcoming items
	const pickUpRefs = React.useRef<HTMLDivElement>(null);
	const pickUpNextItem = React.useCallback(
		(index: number, clickOffset: [number, number]) => {
			if (isActive) {
				const rect =
					pickUpRefs.current?.children[
						index
					].children[0].getBoundingClientRect();
				if (!rect) return undefined;
				const offsetX = rect.left - clickOffset[0];
				const offsetY = rect.top - clickOffset[1];
				setActiveItemOffset([offsetX, offsetY]);
				setActiveItemIndex(index);
			}
		},
		[isActive],
	);

	// handle a button press
	const pressItemButton = React.useCallback(
		(index: number, location: [number, number]) => {
			setCursorLocation(location);
			longAndShortTap.tap(
				() => {
					pickUpNextItem(index, location);
				},
				() => {
					rotate(index);
				},
			);
		},
		[pickUpNextItem, rotate, longAndShortTap],
	);

	// cursor location when dragging items
	const [cursorLocation, setCursorLocation] = React.useState<
		[number, number]
	>([0, 0]);
	React.useEffect(() => {
		const mouseMovement = (e: MouseEvent): void => {
			// sometimes mousemove is triggered by a two finger tap
			// this prevents that from having an effect
			if (touchCount === 0) {
				const location: [number, number] = [e.clientX, e.clientY];
				setCursorLocation(location);
			}
		};
		const touchMovement = (e: TouchEvent): void => {
			touchCount = e.touches.length;
			if (touchCount === 1) {
				const location: [number, number] = [
					e.touches[0].clientX,
					e.touches[0].clientY,
				];
				setCursorLocation(location);
			}
		};
		document.body.addEventListener('mousemove', mouseMovement);
		document.body.addEventListener('touchmove', touchMovement);
		return () => {
			document.body.removeEventListener('mousemove', mouseMovement);
			document.body.removeEventListener('touchmove', touchMovement);
		};
	}, []);

	// calculate active item image position
	const containerRect = containerRef.current?.getBoundingClientRect();
	const activeImageX =
		cursorLocation[0] +
		(activeItemOffset?.[0] || 0) -
		(containerRect?.left || 0);
	const activeImageY =
		cursorLocation[1] +
		(activeItemOffset?.[1] || 0) -
		(containerRect?.top || 0);

	// calculate the position on the board a player has pressed
	// from a mouse or touch event
	const calculateBoardPosition = React.useCallback(
		(location: [number, number]): [number, number] => {
			const rect = boardRef.current?.getBoundingClientRect();
			if (!rect) return [0, 0];
			const scrollTop =
				document.getElementById('scrollableContainer')?.scrollTop || 0;
			const scrollLeft = window.scrollX;
			const offsetX = activeItemOffset?.[0] || 0;
			const offsetY = activeItemOffset?.[1] || 0;
			const positionOnBoardX =
				location[0] - rect.left + offsetX + scrollLeft;
			const positionOnBoardY =
				location[1] - rect.top + offsetY + scrollTop;
			return [positionOnBoardX, positionOnBoardY];
		},
		[activeItemOffset],
	);

	/**
	 * Picking up items already on the board
	 */

	// find an item in the game by grid square
	const getItemIndexByGridSquare = React.useCallback(
		(point: number[]) => {
			const itemIndex = game.setItems.findIndex((item) => {
				const [itemX, itemY] = item.location;
				const shape = shapeRotations[item.itemId][item.rotation];
				const coords: [number, number][] = [];
				shape.forEach((row, rowIndex) => {
					row.forEach((value, colIndex) => {
						if (value === 1) {
							coords.push([colIndex + itemX, rowIndex + itemY]);
						}
					});
				});
				return coords.some(
					([checkX, checkY]) =>
						checkX === point[0] && checkY === point[1],
				);
			});
			return itemIndex;
		},
		[game.setItems],
	);

	// pick up an item from the board
	const pickUpAlreadySetItem = React.useCallback(
		(positionOnBoard: [number, number], itemIndex: number) => {
			const item = game.setItems[itemIndex];

			// pick up the item
			const itemX = item.location[0] * gridSize;
			const itemY = item.location[1] * gridSize;
			const offsetX = itemX - positionOnBoard[0];
			const offsetY = itemY - positionOnBoard[1];
			setActiveItemOffset([offsetX, offsetY]);
			setActiveItemIndex(0);
			events?.onItemPickupFromBoard && events?.onItemPickupFromBoard();

			const newGameState = gameStatePickUpItem(game, itemIndex);
			setGame(newGameState);
		},
		[game, setGame, gridSize, events],
	);

	// rotate an item from the board
	const rotateSetItem = React.useCallback(
		(itemIndex: number) => {
			const item = game.setItems[itemIndex];
			const newRotation = (item.rotation + 1) % 4;

			// calculate potential new location
			const shape = shapeRotations[item.itemId][item.rotation];
			const width = shape[0].length;
			const height = shape.length;
			const offsetX = (width - height) / 2;
			const offsetY = (height - width) / 2;
			const roundedX =
				offsetX < 0 ? Math.ceil(offsetX) : Math.floor(offsetX);
			const roundedY =
				offsetY < 0 ? Math.ceil(offsetY) : Math.floor(offsetY);
			const newX = item.location[0] + roundedX;
			const newY = item.location[1] + roundedY;

			// get squares to check
			const x = newX * gridSize + gridSize / 2;
			const y = newY * gridSize + gridSize / 2;
			const squaresToCheck = getClosestGridReferences(
				[x, y],
				gridSize,
				level,
			);

			// mock picking up the item from the game state
			const stateWithItemRemoved = gameStatePickUpItem(game, itemIndex);
			stateWithItemRemoved.unusedItems[0].rotation = newRotation;

			// look for valid drop locations if rotated
			const nearbyValidDrop = squaresToCheck.find((coords) => {
				return checkValidDrop(
					item.itemId,
					newRotation,
					coords,
					level,
					stateWithItemRemoved,
				);
			});

			// drop the item in the new location
			if (nearbyValidDrop) {
				const stateWithItemDropped = gameStateDroppedItem(
					stateWithItemRemoved,
					0,
					nearbyValidDrop,
				);
				setGame(stateWithItemDropped);
				playAudio('beep');
				events?.onRotate && events.onRotate();
			}
		},
		[game, gridSize, level, setGame, events, playAudio],
	);

	// when we press the board
	const onBoardTap = React.useCallback(
		(location: [number, number]) => {
			if (!isActive) return;
			setCursorLocation(location);

			// check nearby squares for an item to pick up
			const positionOnBoard = calculateBoardPosition(location);
			const pickUpSquares = getClosestGridReferences(
				positionOnBoard,
				gridSize,
				level,
			);
			let itemIndex = -1;
			for (let i = 0; i < pickUpSquares.length; i++) {
				const checkItemIndex = getItemIndexByGridSquare(
					pickUpSquares[i],
				);
				if (checkItemIndex !== -1) {
					itemIndex = checkItemIndex;
					break;
				}
			}
			const item = game.setItems[itemIndex];
			if (item) {
				longAndShortTap.tap(
					() => {
						pickUpAlreadySetItem(positionOnBoard, itemIndex);
					},
					() => {
						rotateSetItem(itemIndex);
					},
				);
			}
		},
		[
			pickUpAlreadySetItem,
			rotateSetItem,
			calculateBoardPosition,
			getItemIndexByGridSquare,
			game.setItems,
			gridSize,
			level,
			isActive,
			longAndShortTap,
		],
	);

	/**
	 * Dropping the active item on the board
	 */

	const [isDropping, dropValid, dropPoint] = React.useMemo(() => {
		if (!isActive || !hasActiveItem || !activeItem) {
			return [false, false, [0, 0]];
		}

		// offset location checker to the middle of the square
		// (this feels more intuitive when you play)
		const location = calculateBoardPosition(cursorLocation);
		const x = location[0] + gridSize / 2;
		const y = location[1] + gridSize / 2;

		// find any nearby squares with a valid move
		const pickUpSquares = getClosestGridReferences([x, y], gridSize, level);
		const nearbyValidDrop = pickUpSquares.find((coords) => {
			return checkValidDrop(
				activeItem.itemId,
				activeItem.rotation,
				coords,
				level,
				game,
			);
		});

		// if we can drop nearby use that, if not then show red where we are
		if (nearbyValidDrop) {
			return [true, true, nearbyValidDrop];
		} else if (pickUpSquares[0]) {
			return [true, false, pickUpSquares[0]];
		} else {
			return [false, false, [0, 0]];
		}
	}, [
		activeItem,
		hasActiveItem,
		calculateBoardPosition,
		cursorLocation,
		game,
		gridSize,
		isActive,
		level,
	]);

	// dropping items
	React.useEffect(() => {
		if (isActive && activeItem && activeItemIndex !== undefined) {
			const dropItem = (): void => {
				if (isDropping && dropValid) {
					playAudio('dropItem');
					const newGame = gameStateDroppedItem(
						game,
						activeItemIndex,
						dropPoint,
					);
					setGame(newGame);
					events?.onItemDrop && events?.onItemDrop();
				}
				setActiveItemIndex(undefined);
				setActiveItemOffset(undefined);
			};
			const mouseUp = (): void => {
				if (touchCount === 0) {
					dropItem();
				}
			};
			const touchEnd = (e: TouchEvent): void => {
				touchCount = e.touches.length;
				if (e.touches.length === 0) {
					dropItem();
				}
			};
			document.body.addEventListener('mouseup', mouseUp);
			document.body.addEventListener('touchend', touchEnd);
			return () => {
				document.body.removeEventListener('mouseup', mouseUp);
				document.body.removeEventListener('touchend', touchEnd);
			};
		}
	}, [
		activeItem,
		activeItemIndex,
		level,
		game,
		setGame,
		playAudio,
		isActive,
		dropPoint,
		dropValid,
		isDropping,
		events,
	]);

	return (
		<div className={styles.game} ref={containerRef}>
			<section
				className={styles.board}
				style={{ width: `${boardWidthPercentage}%` }}
			>
				<div className={styles.boardInner} ref={boardRef}>
					<DrawBoardBackground board={board} gridSize={gridSize} />
					<DrawBoardItems items={game.setItems} gridSize={gridSize} />
					{activeItem && isDropping && (
						<div
							className={styles.itemPreview}
							style={{
								top: dropPoint[1] * gridSize,
								left: dropPoint[0] * gridSize,
							}}
						>
							<DrawItemGrid
								itemId={activeItem.itemId}
								rotation={activeItem.rotation}
								size={gridSize}
								color={dropValid ? 'Green' : 'Red'}
								fill={dropValid ? 'Green' : 'Red'}
							/>
						</div>
					)}
					<div
						className={classNames(
							styles.activeBoard,
							styles.pointerEvents,
						)}
						role="button"
						tabIndex={0}
						onMouseDown={(e) => {
							if (hasTouched) return;
							// sometimes mousedown is triggered by a two finger tap
							// this prevents that from having an effect
							if (touchCount === 0) {
								onBoardTap([e.clientX, e.clientY]);
							}
						}}
						onTouchStart={(e) => {
							if (hasTouched) return;
							touch();
							if (e.touches.length === 1) {
								const { clientX, clientY } = e.touches[0];
								onBoardTap([clientX, clientY]);
							}
						}}
						onMouseUp={() => longAndShortTap.release()}
						onTouchEnd={() => longAndShortTap.release()}
					></div>
				</div>
			</section>
			<section className={styles.itemRow} ref={pickUpRefs}>
				{game.unusedItems.length === 0 && (
					<div className={styles.itemRowItem}></div>
				)}
				{game.unusedItems.slice(0, 5).map((unusedItem, i) => (
					<div className={styles.itemRowItem} key={i}>
						<Button
							key={i}
							variant={
								unusedItem.required
									? ButtonVariant.Yellow
									: ButtonVariant.White
							}
							className={styles.pointerEvents}
							onMouseDown={(e) => {
								if (hasTouched) return;
								pressItemButton(i, [e.clientX, e.clientY]);
								playAudio('pickItem');
							}}
							onTouchStart={(e) => {
								if (hasTouched) return;
								touch();
								if (e.touches.length === 1) {
									const { clientX, clientY } = e.touches[0];
									pressItemButton(i, [clientX, clientY]);
									playAudio('pickItem');
								}
							}}
							onMouseUp={() => longAndShortTap.release()}
							onTouchEnd={() => longAndShortTap.release()}
							sound="beep"
						>
							<DrawItemImage
								itemId={unusedItem.itemId}
								rotation={unusedItem.rotation}
								size={14}
								center
							/>
						</Button>
					</div>
				))}
			</section>
			{activeItem && (
				<div
					className={styles.activeItem}
					style={{
						top: activeImageY,
						left: activeImageX,
					}}
				>
					<DrawItemImage
						itemId={activeItem.itemId}
						rotation={activeItem.rotation}
						size={gridSize * 1.05}
					/>
				</div>
			)}
		</div>
	);
};
