import React from 'react';
import { AudioOnButton } from 'src/components/atoms/audio-on-button';
import { Button, ButtonVariant } from 'src/components/atoms/button';
import { IconButton, EIcon } from 'src/components/atoms/icon-button';
import { MobileScreenWrapper } from 'src/components/atoms/mobile-screen-wrapper';
import { Modal } from 'src/components/atoms/modal';
import { Titlebar } from 'src/components/atoms/titlebar';
import data from 'src/components/screens/game/data';
import { useActiveLevel } from 'src/context/active-level';
import { EScreen, useScreen } from 'src/context/screen';
import { isTouchScreen } from 'src/utils/is-touch-screen';
import { setLocalStorage } from 'src/utils/local-storage';
import { GameEngine } from '../game/engine';
import { createGame } from '../game/helpers';
import styles from './index.module.scss';
import thumb from 'src/images/icons/thumb-up.png';
import { useBackgroundMusic } from 'src/utils/audio';

const content = {
	title: 'Tutorial',
	completeTitle: 'Tutorial complete',
	completeBody:
		"Now you're a boot-packing pro, it's time for you to test your skills and pack for a real trip (kind of)...",
};

const stepMessages: { [key: string]: string } = {
	drag: 'Drag an item from the row and drop it onto the board.',
	dragDone: "Lovely, you're a natural.",
	dragOnBoard: 'Now try to drag the same item to somewhere else in the boot.',
	dragOnBoardDone: 'Wonderful.',
	rotate: isTouchScreen()
		? "Now let's rotate an item - quickly tap an item in the bar - OR - tap with a second finger while dragging to rotate your item."
		: "Now let's rotate an item - quickly click an item in the bar - OR - press the 'r' key while dragging to rotate your item.",
	rotateDone: 'Amazing, last thing to learn now.',
	required:
		'Required items are needed to finish the game and are highlighted in GOLD. Optional items are in white. Drag in the required items to be ready for your trip!',
	requiredDone:
		'Great job - now you can close the boot and get on the road, or try to fill the boot as much as possible for a higher score!',
};

const setLocal = (): void => setLocalStorage('hasCompletedTutorial', true);

export const TutorialScreen: React.FC = () => {
	useBackgroundMusic('home');

	const [, setScreen] = useScreen();
	const [, setActiveLevel] = useActiveLevel();
	const [finished, setFinished] = React.useState(false);
	const [active, setActive] = React.useState(true);

	const goToFirstLevel = (): void => {
		setActiveLevel(data.levelOrder[0].levels[0]);
		setScreen(EScreen.Game);
	};

	const [game, setGame] = React.useState(() =>
		createGame(data.levels.tutorial1, 'tutorial1'),
	);

	const [messageKey, setMessageKey] = React.useState('drag');
	const [itemPickedUp, setItemPickedUp] = React.useState(false);

	const onItemDrop = React.useCallback(() => {
		if (messageKey === 'drag') {
			setMessageKey('dragDone');
			setItemPickedUp(false);
			setActive(false);
			setTimeout(() => {
				setActive(true);
				setMessageKey('dragOnBoard');
			}, 1500);
		} else if (messageKey === 'dragOnBoard') {
			if (itemPickedUp) {
				setMessageKey('dragOnBoardDone');
				setActive(false);
				setTimeout(() => {
					setActive(true);
					setMessageKey('rotate');
				}, 1000);
			}
		}
	}, [itemPickedUp, messageKey]);

	const onRotate = React.useCallback(() => {
		if (messageKey === 'rotate') {
			setMessageKey('rotateDone');
			setItemPickedUp(false);
			setTimeout(() => {
				setMessageKey('required');
				setGame(createGame(data.levels.tutorial2, 'tutorial2'));
			}, 1500);
		}
	}, [messageKey]);

	const onItemPickupFromBoard = React.useCallback(
		(): void => setItemPickedUp(true),
		[],
	);

	// level requirements
	const requirementsMet = React.useMemo(
		() => game?.requiredItems.length === 0,
		[game],
	);

	// are we done
	React.useEffect(() => {
		if (requirementsMet && messageKey === 'required') {
			setMessageKey('requiredDone');
		}
		if (!requirementsMet && messageKey === 'requiredDone') {
			setMessageKey('required');
		}
	}, [messageKey, requirementsMet]);

	// which step are we on
	const step = React.useMemo(() => {
		if ('drag' === messageKey) {
			return 1;
		}
		if (['dragDone', 'dragOnBoard'].includes(messageKey)) {
			return 2;
		}
		if (['dragOnBoardDone', 'rotate'].includes(messageKey)) {
			return 3;
		}
		if (['rotateDone', 'required'].includes(messageKey)) {
			return 4;
		}
		if ('requiredDone' === messageKey) {
			return 5;
		}
		return 0;
	}, [messageKey]);

	const replay = (): void => {
		setGame(createGame(data.levels.tutorial1, 'tutorial1'));
		setMessageKey('drag');
		setFinished(false);
	};

	return (
		<MobileScreenWrapper className={styles.bg}>
			<Titlebar>
				<div className={styles.title}>
					<IconButton
						icon={EIcon.Home}
						onClick={() => {
							setScreen(EScreen.Home);
						}}
					/>
					<h1>{content.title}</h1>
					<AudioOnButton />
				</div>
			</Titlebar>
			{game && (
				<GameEngine
					game={game}
					setGame={setGame}
					isActive={active}
					events={{
						onItemDrop,
						onRotate,
						onItemPickupFromBoard,
					}}
				/>
			)}
			{messageKey === 'required' && (
				<div className={styles.requirementsText}>
					Required items remaining:{' '}
					<b>{game?.requiredItems.length}</b>
				</div>
			)}
			{messageKey === 'requiredDone' && (
				<Button
					className={styles.bootButton}
					onClick={() => {
						setLocal();
						setFinished(true);
					}}
				>
					Close boot
				</Button>
			)}
			<div className={styles.message}>{stepMessages[messageKey]}</div>
			<div className={styles.steps}>
				<div style={{ width: `${step * 20}%` }}></div>
			</div>
			<div className={styles.skipButton}>
				<Button
					variant={ButtonVariant.White}
					onClick={() => {
						setLocal();
						goToFirstLevel();
					}}
				>
					Skip Tutorial
				</Button>
			</div>
			<Modal active={finished}>
				<div className={styles.thumb}>
					<img src={thumb} alt="" />
				</div>
				<h2>{content.completeTitle}</h2>
				<p>{content.completeBody}</p>
				<Button
					className={styles.btnMargin}
					variant={ButtonVariant.Red}
					onClick={() => {
						goToFirstLevel();
					}}
				>
					Level 1
				</Button>
				<Button variant={ButtonVariant.White} onClick={() => replay()}>
					Replay tutorial
				</Button>
			</Modal>
		</MobileScreenWrapper>
	);
};
