import React, { Dispatch, SetStateAction } from 'react';
import data from './data';
import { TDataLevel } from './data/types';

/**
 * Hook to calculate grid size from the size of the board
 * Recalculates every time window resizes
 */

export function useGridSize(
	boardRef: React.RefObject<HTMLDivElement>,
	level: TDataLevel,
): number {
	const board = data.boards[level.boardId];
	const [gridSize, setGridSize] = React.useState(36);

	// reset the grid size
	const set = React.useCallback(() => {
		const rect = boardRef.current?.getBoundingClientRect();
		const width = rect?.width || 0;
		const newSize = Math.round((width / board[0].length) * 10) / 10;
		setGridSize(newSize);
	}, [boardRef, board]);

	// set it at the beginning
	React.useEffect(() => {
		set();
	}, [set]);

	// check for window resize
	React.useEffect(() => {
		const onresize = (): void => {
			set();
		};
		window.addEventListener('resize', onresize);
		return () => {
			window.removeEventListener('resize', onresize);
		};
	}, [set]);

	return gridSize;
}

/**
 * Handle long taps or short taps
 */

type TLongAndShortTap = (
	longTap: () => unknown,
	shortTap: () => unknown,
) => void;

type TUseLongAndShortTap = {
	tap: TLongAndShortTap;
	release: () => void;
};

export function useLongAndShortTap(): TUseLongAndShortTap {
	const isPressed = React.useRef(false);
	const tap: TLongAndShortTap = React.useCallback((longTap, shortTap) => {
		isPressed.current = true;
		setTimeout(() => {
			if (isPressed.current) {
				longTap();
			} else {
				shortTap();
			}
		}, 150);
	}, []);
	const release = (): void => {
		isPressed.current = false;
	};
	return { tap, release };
}

/**
 * Prevent immediate mouse clicks after touch events
 */

type TPreventTouchEventDuplication = {
	hasTouched: boolean;
	touch: () => void;
};

export function usePreventTouchEventDuplication(): TPreventTouchEventDuplication {
	const hasTouched = React.useRef(false);
	const touch = (): void => {
		hasTouched.current = true;
		setTimeout(() => {
			hasTouched.current = false;
		}, 20);
	};
	return { hasTouched: hasTouched.current, touch };
}
