import classNames from 'classnames';
import React from 'react';
import audiooff from 'src/images/icons/audiooff.png';
import audioon from 'src/images/icons/audioon.png';
import help from 'src/images/icons/help.png';
import home from 'src/images/icons/home.png';
import open from 'src/images/icons/open.png';
import pause from 'src/images/icons/pause.png';
import { usePlayAudio } from 'src/utils/audio';
import styles from './index.module.scss';

const icons = {
	home,
	pause,
	open,
	help,
	audioon,
	audiooff,
};

export enum EIcon {
	Home = 'home',
	Pause = 'pause',
	Open = 'open',
	Help = 'help',
	AudioOn = 'audioon',
	AudioOff = 'audiooff',
}

type TIconButtonShared = {
	icon?: EIcon;
	onClick?: () => void;
	audioOn?: boolean;
};

export type TIconButton = React.ButtonHTMLAttributes<HTMLButtonElement> &
	TIconButtonShared;

export const IconButton: React.FC<TIconButton> = ({
	icon = EIcon.Home,
	type = 'button',
	onClick,
	audioOn = true,
	className,
	...buttonAttributes
}) => {
	const playAudio = usePlayAudio();
	return (
		<button
			className={classNames(styles.btn, className)}
			onClick={() => {
				onClick?.();
				audioOn && playAudio('buttonPress');
			}}
			{...buttonAttributes}
			type={type}
		>
			<img src={icons[icon]} alt={icon} />
		</button>
	);
};
