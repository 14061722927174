import React from 'react';
import { getLocalStorage, setLocalStorage } from 'src/utils/local-storage';
import { Button, ButtonRow, ButtonVariant } from '../button';
import { Modal } from '../modal';
import styles from './index.module.scss';
import open from 'src/images/icons/open.png';

// the tracking won't run until the staging environment
// unless the env var is set manually
const gtagId = process.env.REACT_APP_GTAG_ID;

function startTracking(): void {
	if (gtagId) {
		const script1 = document.createElement('script');
		script1.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;

		const script2 = document.createElement('script');
		script2.text = `
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', '${gtagId}');
		`;

		document.head.appendChild(script1);
		document.head.appendChild(script2);
	}
}

type TCookiePopup = [() => void, React.ReactNode];

export const useCookiePopup = (): TCookiePopup => {
	const [showPopup, setShowPopup] = React.useState(false);
	React.useEffect(() => {
		const tracking = getLocalStorage('tracking');
		if (tracking === undefined) {
			setShowPopup(true);
		}
		if (tracking === 'true') {
			startTracking();
		}
	}, []);

	const show = (): void => setShowPopup(true);

	const popup = (
		<Modal active={showPopup} className={styles.cookies}>
			<h2>Cookies make road trips better</h2>
			<p>
				We use some essential cookies to make this website work. We'd
				like to set additional cookies to understand how you use this
				website, remember your settings, and improve our service.
			</p>
			<p>
				<a
					href="https://www.jct600.co.uk/pages/company/cookies/"
					target="_blank"
					rel="noreferrer"
				>
					<img
						className="link-icon"
						src={open}
						alt="Opens in new tab"
					/>
					Cookie policy
				</a>
			</p>

			<ButtonRow>
				<Button
					variant={ButtonVariant.Red}
					onClick={() => {
						setLocalStorage('tracking', 'true');
						startTracking();
						setShowPopup(false);
					}}
				>
					Accept
				</Button>
				<Button
					variant={ButtonVariant.Red}
					onClick={() => {
						setLocalStorage('tracking', 'false');
						setShowPopup(false);
					}}
				>
					Decline
				</Button>
			</ButtonRow>
		</Modal>
	);

	return [show, popup];
};
