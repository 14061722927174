import React from 'react';
import { AudioOnButton } from 'src/components/atoms/audio-on-button';
import { Button, ButtonRow, ButtonVariant } from 'src/components/atoms/button';
import { useCookiePopup } from 'src/components/atoms/cookie-popup';
import { IconButton, EIcon } from 'src/components/atoms/icon-button';
import { MobileScreenWrapper } from 'src/components/atoms/mobile-screen-wrapper';
import data from 'src/components/screens/home/data';
import { useActiveLevel } from 'src/context/active-level';
import { useLevelProgress } from 'src/context/level-progress';
import { EScreen, useScreen } from 'src/context/screen';
import { useBackgroundMusic } from 'src/utils/audio';
import { getLocalStorage } from 'src/utils/local-storage';
import logo from '../../../images/logo.png';
import { flatLevelIds } from '../game/data/computed';
import styles from './index.module.scss';

export const HomeScreen: React.FC = () => {
	const [showCookiePopup, cookiePopup] = useCookiePopup();
	useBackgroundMusic('home');

	const [, setScreen] = useScreen();
	const [, setActiveLevel] = useActiveLevel();

	const titleAlt = data.content.titleAlt;
	const subtitle = data.content.subtitle;

	// get next level id
	const [levelProgress] = useLevelProgress();
	const nextLevelId = React.useMemo(() => {
		const incompleteLevel = flatLevelIds.find(
			(levelId) => !levelProgress[levelId],
		);
		if (incompleteLevel) {
			return incompleteLevel;
		} else {
			const nonPerfectLevel = flatLevelIds.find(
				(levelId) => !levelProgress[levelId].perfectPack,
			);
			if (nonPerfectLevel) {
				return nonPerfectLevel;
			} else {
				return flatLevelIds[0];
			}
		}
	}, [levelProgress]);

	// get button text for starting to play
	const startText = React.useMemo(() => {
		const incompleteLevel = flatLevelIds.find(
			(levelId) => !levelProgress[levelId],
		);
		return incompleteLevel ? 'Get Packing' : 'Pack again';
	}, [levelProgress]);

	return (
		<>
			<MobileScreenWrapper className={styles.home}>
				<div className={styles.content}>
					<h1 className={styles.logo}>
						<img src={logo} alt="Car Boot Carnage" />
						<a
							href="https://www.jct600.co.uk/"
							className={styles.logoLink}
						>
							<span className="visually-hidden">
								Created by JCT600
							</span>
						</a>
					</h1>
					<h2 className={styles.subtitle}>{subtitle}</h2>
					<Button
						onClick={() => {
							const hasCompletedTutorial = getLocalStorage(
								'hasCompletedTutorial',
							);
							if (hasCompletedTutorial) {
								setActiveLevel(nextLevelId);
								setScreen(EScreen.Game);
							} else {
								setScreen(EScreen.Tutorial);
							}
						}}
						className={styles.btn}
					>
						{startText}
					</Button>
					<Button
						variant={ButtonVariant.White}
						onClick={() => setScreen(EScreen.LevelSelect)}
						className={styles.btn}
					>
						Level Select
					</Button>
					<ButtonRow>
						<AudioOnButton />
						<IconButton
							icon={EIcon.Help}
							onClick={() => setScreen(EScreen.About)}
						/>
					</ButtonRow>
				</div>
				<button
					className={styles.cookieChange}
					onClick={() => showCookiePopup()}
				>
					Change cookie preferences
				</button>
			</MobileScreenWrapper>
			{cookiePopup}
		</>
	);
};
