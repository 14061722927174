import React from 'react';

type TActiveLevelContext = [string, (id: string) => void];

const Context = React.createContext<TActiveLevelContext>([
	'',
	() => {
		return undefined;
	},
]);

export const ActiveLevelProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [value, setValue] = React.useState('');
	return (
		<Context.Provider value={[value, setValue]}>
			{children}
		</Context.Provider>
	);
};

export const useActiveLevel = (): TActiveLevelContext => {
	return React.useContext(Context);
};
