import React from 'react';
import { useAudioOn } from 'src/context/audio-on';
import { IconButton, EIcon } from '../icon-button';
import { stopAllAudio } from 'src/utils/audio';

export const AudioOnButton: React.FC = () => {
	const [audioOn, setAudioOn] = useAudioOn();
	return audioOn ? (
		<IconButton
			icon={EIcon.AudioOn}
			onClick={() => {
				setAudioOn(false);
				stopAllAudio();
			}}
			// Mute audio if user is going to turn off audio button as that could be quite jarring
			audioOn={false}
		/>
	) : (
		<IconButton icon={EIcon.AudioOff} onClick={() => setAudioOn(true)} />
	);
};
