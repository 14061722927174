import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';
import { usePlayAudio } from 'src/utils/audio';

export enum ButtonVariant {
	Red = 'red',
	White = 'white',
	Yellow = 'yellow',
	Blue = 'blue',
	Transparent = 'transparent',
}

type TButtonShared = {
	variant?: ButtonVariant;
	children: React.ReactNode;
	onClick?: () => void;
	sound?: string;
};

export type TButton = React.ButtonHTMLAttributes<HTMLButtonElement> &
	TButtonShared;

export const Button: React.FC<TButton> = ({
	variant = ButtonVariant.Red,
	children,
	type = 'button',
	className,
	onClick,
	sound = 'buttonPress',
	...buttonAttributes
}) => {
	const playAudio = usePlayAudio();
	return (
		<button
			className={classNames(styles.btn, styles[variant], className)}
			onClick={() => {
				onClick?.();
				playAudio(sound);
			}}
			{...buttonAttributes}
			type={type}
		>
			{children}
		</button>
	);
};

type TButtonList = {
	children: React.ReactNode;
};

export const ButtonList: React.FC<TButtonList> = ({ children }) => {
	return <div className={styles.buttonList}>{children}</div>;
};

export const ButtonRow: React.FC<TButtonList> = ({ children }) => {
	return <div className={styles.buttonRow}>{children}</div>;
};
