import React from 'react';
import styles from './index.module.scss';
import starFull from 'src/images/star-full.png';
import starHalf from 'src/images/star-half.png';
import starEmpty from 'src/images/star-empty.png';
import classnames from 'classnames';

type TStarRating = React.HTMLAttributes<HTMLDivElement> & {
	rating?: number;
};

export const StarRating: React.FC<TStarRating> = ({ rating, className }) => {
	let first = starEmpty;
	let second = starEmpty;
	let third = starEmpty;

	if (rating === 0.5) {
		first = starHalf;
	} else if (rating === 1) {
		first = starFull;
	} else if (rating === 1.5) {
		first = starFull;
		second = starHalf;
	} else if (rating === 2) {
		first = starFull;
		second = starFull;
	} else if (rating === 2.5) {
		first = starFull;
		second = starFull;
		third = starHalf;
	} else if (rating === 3) {
		first = starFull;
		second = starFull;
		third = starFull;
	}

	return (
		<div className={classnames(styles.stars, className)}>
			<img src={first} alt="" />
			<img src={second} alt="" />
			<img src={third} alt="" />
		</div>
	);
};
