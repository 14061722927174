import React from 'react';
import { Button, ButtonList, ButtonVariant } from 'src/components/atoms/button';
import { IconButton, EIcon } from 'src/components/atoms/icon-button';
import { MobileScreenWrapper } from 'src/components/atoms/mobile-screen-wrapper';
import { Modal } from 'src/components/atoms/modal';
import { EScreen, useScreen } from 'src/context/screen';
import { useBackgroundMusic } from 'src/utils/audio';

import signLink from '../../../images/about-link.png';
import carIcon from '../../../images/car-front.png';
import styles from './index.module.scss';

export const CompleteScreen: React.FC = () => {
	useBackgroundMusic('home');
	const [, setScreen] = useScreen();

	return (
		<MobileScreenWrapper className={styles.completed}>
			<Modal active>
				<div className={styles.contentWrapper}>
					<img src={carIcon} alt="" />
					<h1 className={styles.title}>Congratulations</h1>
					<p>
						<b>
							You have packed superbly and have completed all the
							levels! Do you want to play your favourite level
							again and see if you can beat your high score?
						</b>
					</p>
					<ButtonList>
						<Button
							variant={ButtonVariant.Red}
							onClick={() => setScreen(EScreen.LevelSelect)}
						>
							Level Select
						</Button>
						<Button
							variant={ButtonVariant.Transparent}
							onClick={() => setScreen(EScreen.Home)}
						>
							Return Home
						</Button>
					</ButtonList>
				</div>
			</Modal>
			<a href="https://jct600.co.uk" className={styles.link}>
				<img src={signLink} alt="https://jct600.co.uk" />
			</a>
		</MobileScreenWrapper>
	);
};
