import React from 'react';
import styles from './index.module.scss';

type TTitlebar = {
	children: React.ReactNode;
};

export const Titlebar: React.FC<TTitlebar> = ({ children }) => {
	return <div className={styles.titlebar}>{children}</div>;
};
