import data from '.';

export const itemImages = Object.fromEntries(
	Object.entries(data.items).map(([key, item]) => {
		const loaded = require(`src/images/items/${item.image}`);
		return [key, loaded];
	}),
);

export const themeImages = Object.fromEntries(
	Object.entries(data.themes).map(([key, item]) => {
		const loaded = require(`src/images/themes/${item.backgroundImage}`);
		return [key, loaded];
	}),
);
