import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

type TModal = {
	children: React.ReactNode;
	active: boolean;
	className?: string;
};

export const Modal: React.FC<TModal> = ({ active, children, className }) => {
	if (!active) {
		return null;
	}
	return (
		<>
			<div className={styles.background}></div>
			<div className={classNames(styles.foreground, className)}>
				{children}
			</div>
		</>
	);
};
