import data from '.';

/**
 * Generate all 4 shape rotations from the initial shape of an item
 *
 * Example output:
 * {
 *   "t": [
 *     [ // 0 degrees
 *       [0,1,0],
 *       [1,1,1],
 *     ],
 *     [ // 90 degrees
 *       [1,0],
 *       [1,1],
 *       [1,0]
 *     ],
 *     [ // 180 degrees
 *       [1,1,1],
 *       [0,1,0],
 *     ],
 *     [ // 270 degrees
 *       [0,1],
 *       [1,1],
 *       [0,1]
 *     ],
 *   ]
 * }
 */

type TShapeRotations = { [key: string]: number[][][] };

export const shapeRotations: TShapeRotations = Object.fromEntries(
	Object.entries(data.items).map(([id, item]) => {
		const initialShape = item.shape;
		const eachRotation = Array.from({ length: 4 }).map((_, rotation) => {
			if (rotation === 1) {
				// 90 degrees
				return initialShape[0].map((_, index) =>
					initialShape.map((row) => row[index]).reverse(),
				);
			} else if (rotation === 2) {
				// 180 degrees
				return initialShape.map((row) => [...row].reverse()).reverse();
			} else if (rotation === 3) {
				// 270 degrees
				return initialShape[0]
					.map((_, index) => initialShape.map((row) => row[index]))
					.reverse();
			}
			// unchanged
			return initialShape;
		});
		return [id, eachRotation];
	}),
);

/**
 * Generate an array of all available item ID's
 */

export const itemIds: string[] = Object.keys(data.items);

/**
 * Organise item id's by complexity
 */

type TItemsByComplexity = string[][];

function getItemsByComplexity(itemIds: string[]): TItemsByComplexity {
	const arr: TItemsByComplexity = Array.from({ length: 10 }).map(() => []);
	itemIds.forEach((itemId) => {
		const item = data.items[itemId];
		arr[item.complexity].push(itemId);
	});
	return arr;
}

export const itemsByComplexity = ((): TItemsByComplexity => {
	return getItemsByComplexity(Object.keys(data.items));
})();

/**
 * Organise item id's by theme and compplexity
 */

type TItemsByThemeAndComplexity = { [key: string]: TItemsByComplexity };

export const itemsByThemeAndComplexity = ((): TItemsByThemeAndComplexity => {
	const output: TItemsByThemeAndComplexity = {};
	Object.entries(data.themes).forEach(([themeId, theme]) => {
		output[themeId] = getItemsByComplexity(theme.items);
	});
	return output;
})();

/**
 * Generate item reference by shape size
 */

type TItemSizes = { [key: string]: number };

export const itemSizes: TItemSizes = Object.fromEntries(
	Object.entries(data.items).map(([id, item]) => {
		const size = item.shape.flat().filter((value) => !!value).length;
		return [id, size];
	}),
);

/**
 * Generate an array of available non-blocked co-ordinates in any given board shape
 *
 * Example output: [[0,0], [0,1], [1,1]]
 */

type TBoardAvailableSquares = { [key: string]: [number, number][] };

export const boardAvailableSquares: TBoardAvailableSquares = Object.fromEntries(
	Object.entries(data.boards).map(([id, board]) => {
		const coords: [number, number][] = [];
		board.forEach((row, rowIndex) => {
			row.forEach((value, colIndex) => {
				if (value === 0) {
					coords.push([colIndex, rowIndex]);
				}
			});
		});
		return [id, coords];
	}),
);

/**
 * Generate flat list of levels in order
 */

export const flatLevelIds = ((): string[] => {
	const arr: string[] = [];
	data.levelOrder.forEach((levelOrder) => {
		arr.push(...levelOrder.levels);
	});
	return arr;
})();
