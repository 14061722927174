import React from 'react';
import { IconButton, EIcon } from 'src/components/atoms/icon-button';
import { MobileScreenWrapper } from 'src/components/atoms/mobile-screen-wrapper';
import { EScreen, useScreen } from 'src/context/screen';
import data from 'src/components/screens/about/data';
import signLink from '../../../images/about-link.png';
import styles from './index.module.scss';
import { useBackgroundMusic, usePlayAudio } from 'src/utils/audio';

export const AboutScreen: React.FC = () => {
	useBackgroundMusic('home');

	const [, setScreen] = useScreen();
	const url = data.content.url;
	const playAudio = usePlayAudio();

	return (
		<MobileScreenWrapper className={styles.about}>
			<div className={styles.buttonWrapper}>
				<IconButton
					icon={EIcon.Home}
					onClick={() => setScreen(EScreen.Home)}
				/>
			</div>

			<h1 className={styles.title}>
				Car Boot Carnage was created with love by
				<span>JCT600</span>
			</h1>

			<a
				href={url}
				className={styles.link}
				onClick={() => {
					playAudio('buttonPress');
				}}
			>
				<img src={signLink} alt={url} />
			</a>
		</MobileScreenWrapper>
	);
};
