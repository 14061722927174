import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

type TMobileScreenWrapper = React.HTMLAttributes<HTMLDivElement> & {
	scrollable?: boolean;
	children: React.ReactNode;
};

export const MobileScreenWrapper: React.FC<TMobileScreenWrapper> = ({
	children,
	scrollable,
	className,
	...otherProps
}) => {
	const [height, setHeight] = React.useState<number | 'auto'>('auto');
	React.useEffect(() => {
		if (!scrollable) {
			const fixHeight = (): void => setHeight(window.innerHeight);
			fixHeight();
			window.addEventListener('resize', fixHeight);
			return () => window.removeEventListener('resize', fixHeight);
		} else {
			setHeight('auto');
		}
	}, [scrollable]);
	return (
		<main
			className={classNames(
				styles.wrapper,
				!scrollable && styles.fixed,
				className,
			)}
			style={{ height }}
			{...otherProps}
		>
			{children}
		</main>
	);
};
