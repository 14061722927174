import data from 'src/components/screens/game/data';
import {
	boardAvailableSquares,
	itemsByComplexity,
	itemsByThemeAndComplexity,
	itemSizes,
	shapeRotations,
} from 'src/components/screens/game/data/computed';
import { TDataLevel } from 'src/components/screens/game/data/types';
import { TLevelPlayed } from 'src/context/level-progress';
import { TBoardItem, TGameInProgress } from './types';

/**
 * Get a new random item from the levels randomised weights
 */

export function getRandomItem(
	level: TDataLevel,
	game: TGameInProgress,
): string {
	const possibleChoices: string[] = [];

	// calculate numbers of required items that still need to be included in the game
	const requiredItems: { [key: string]: number } = {};
	game.requiredItems?.forEach((itemId) => {
		if (requiredItems[itemId]) {
			requiredItems[itemId]++;
		} else {
			requiredItems[itemId] = 1;
		}
	});

	// remove number of items already in game
	Object.keys(requiredItems).forEach((itemId) => {
		const numberAlreadyInUnused = game.unusedItems.filter(
			(unusedItem) => unusedItem.itemId === itemId,
		).length;
		requiredItems[itemId] -= numberAlreadyInUnused;
	});

	// build array of remaining required items
	const requiredItemIds: string[] = [];
	Object.entries(requiredItems).forEach(([itemId, n]) => {
		for (let i = 0; i < n; i++) {
			requiredItemIds.push(itemId);
		}
	});

	// increase chance of required items when space is low
	const spaceRemaining = calculateRemainingSquares(game);
	let sizeOfRemainingRequired = 0;
	game.requiredItems.forEach((itemId) => {
		sizeOfRemainingRequired += itemSizes[itemId];
	});
	const projectedSpace = spaceRemaining - sizeOfRemainingRequired;
	const weighting = 20;
	const requiredMultiplier = Math.max(
		2,
		-(Math.pow(projectedSpace - weighting, 3) / 300),
	);

	// add required items to pool
	requiredItemIds.forEach((itemId) => {
		const item = data.items[itemId];
		const numberInPool = Math.round(
			(item.complexity / 2 + 2) * requiredMultiplier,
		);
		possibleChoices.push(
			...Array.from({ length: numberInPool }).map(() => itemId),
		);
	});

	const unusedItemIds = game.unusedItems.map((d) => d.itemId);
	if (level.randomItemWeights) {
		// build an array of possible choices by adding the number of random items
		// from a particular complexity that matches the weight in the level
		level.randomItemWeights.forEach((weight, complexity) => {
			const itemIdsByComplexity = level.theme
				? itemsByThemeAndComplexity[level.theme]
				: itemsByComplexity;

			// remove any items already in the list from the pool
			const itemIds = itemIdsByComplexity[complexity].filter(
				(checkItemId) => {
					return !unusedItemIds.includes(checkItemId);
				},
			);
			if (itemIds.length) {
				possibleChoices.push(
					...Array.from({ length: weight }).map(() => {
						return itemIds[
							Math.floor(Math.random() * itemIds.length)
						];
					}),
				);
			}
		});
	}

	// return a random one of the possible choices
	const choice =
		possibleChoices[Math.floor(Math.random() * possibleChoices.length)];
	return choice;
}

/**
 * Create new game from scratch
 */

export function createGame(
	level: TDataLevel,
	levelId: string,
): TGameInProgress | undefined {
	if (!level || !levelId) {
		return;
	}
	const game: TGameInProgress = {
		unusedItems: (level.initialItems || []).map((itemId) => ({
			itemId,
			rotation: 0,
			required: false,
		})),
		setItems: [...(level.presetItems || [])],
		requiredItems: [...(level.requiredItems || [])],
		occupiedSquares: [],
		levelId,
	};

	// randomise initial objects
	// how many random items to add (we want 6 to start)
	const length = Math.max(0, 6 - game.unusedItems.length);
	for (let i = 0; i < length; i++) {
		const randomItem = getRandomItem(level, game);
		if (randomItem) {
			game.unusedItems.push({
				itemId: randomItem,
				rotation: 0,
				required: false,
			});
		}
	}
	return gameStateSideEffects(game);
}

/**
 * Calculate occupied squares by set items
 */

export function calculateOccupiedSquares(setItems: TBoardItem[]): number[][] {
	const arr: [number, number][] = [];
	setItems.forEach((item) => {
		const [x, y] = item.location;
		const rotatedShape = shapeRotations[item.itemId][item.rotation];
		rotatedShape.forEach((row, rowIndex) => {
			row.forEach((value, colIndex) => {
				if (value === 1) {
					arr.push([x + colIndex, y + rowIndex]);
				}
			});
		});
	});
	return arr;
}

/**
 * Calculate remaining squares on the board
 */

export function calculateRemainingSquares(game: TGameInProgress): number {
	const occupiedSquares = squaresFilled(game);
	const level = data.levels[game.levelId];
	const available = boardAvailableSquares[level.boardId].length;
	return available - occupiedSquares;
}

/**
 * Get game state with an item removed from the board
 */

export function gameStatePickUpItem(
	game: TGameInProgress,
	itemIndex: number,
): TGameInProgress {
	const item = game.setItems[itemIndex];

	const changes = {
		setItems: [...game.setItems],
		requiredItems: [...game.requiredItems],
		unusedItems: [
			{
				itemId: item.itemId,
				rotation: item.rotation,
				required: false,
			},
			...game.unusedItems,
		],
	};
	changes.setItems.splice(itemIndex, 1);
	if (item.required) {
		changes.requiredItems.unshift(item.itemId);
	}
	return gameStateSideEffects(game, changes);
}

/**
 * Get game state with an item dropped on the board
 */

export function gameStateDroppedItem(
	game: TGameInProgress,
	itemIndex: number,
	dropLocation: number[],
): TGameInProgress {
	const changes = {
		requiredItems: [...game.requiredItems],
		unusedItems: [...game.unusedItems],
		setItems: [...game.setItems],
	};

	// extract the new item from unused items
	const droppingItem = changes.unusedItems.splice(itemIndex, 1)[0];

	// check if we need to remove from the required array
	const removeRequiredIndex = changes.requiredItems.findIndex(
		(id) => id === droppingItem.itemId,
	);
	const isRequired = removeRequiredIndex !== -1;
	if (isRequired) {
		changes.requiredItems.splice(removeRequiredIndex, 1);
	}

	// put the new item on the board
	const newSetItem = {
		itemId: droppingItem.itemId,
		rotation: droppingItem.rotation,
		location: dropLocation,
		required: isRequired,
	};
	changes.setItems.push(newSetItem);
	const newGame = gameStateSideEffects(game, changes);

	// add new items to unused if necessary
	const level = data.levels[game.levelId];
	if (newGame.unusedItems.length < 6) {
		const newItem = getRandomItem(level, newGame);
		if (newItem) {
			newGame.unusedItems.push({
				itemId: getRandomItem(level, newGame),
				rotation: 0,
				required: false,
			});
		}
	}

	return gameStateSideEffects(newGame);
}

/**
 * Update the game state including any computer state changes (eg: occupied squares)
 */

export function gameStateSideEffects(
	oldGame: TGameInProgress,
	changes: Partial<TGameInProgress> = {},
): TGameInProgress {
	const newGame = { ...oldGame, ...changes };

	// set occupied squares
	newGame.occupiedSquares = calculateOccupiedSquares(newGame.setItems);

	// set required item highlights in unused items
	const remainingRequired = [...newGame.requiredItems];
	newGame.unusedItems.forEach((unusedItem) => {
		const indexInRemaining = remainingRequired.findIndex(
			(requiredId) => requiredId === unusedItem.itemId,
		);
		let required = false;
		if (indexInRemaining !== -1) {
			required = true;
			remainingRequired.splice(indexInRemaining, 1);
		}
		unusedItem.required = required;
	});

	return newGame;
}

/**
 * Check if you can drop an item at a particular square
 */

export function checkValidDrop(
	itemId: string,
	itemRotation: number,
	location: number[],
	level: TDataLevel,
	game: TGameInProgress,
): boolean {
	let isValid = true;
	const [x, y] = location;
	const rotatedShape = shapeRotations[itemId][itemRotation];
	rotatedShape.forEach((row, rowIndex) =>
		row.forEach((value, colIndex) => {
			if (value === 1) {
				const checkX = colIndex + x;
				const checkY = rowIndex + y;
				const isOccupied = game.occupiedSquares.some(
					([occupiedX, occupiedY]) =>
						occupiedX === checkX && occupiedY === checkY,
				);
				const availableSquares = boardAvailableSquares[level.boardId];
				const isWithinBoard = availableSquares.some(
					([inBoardX, inBoardY]) =>
						inBoardX === checkX && inBoardY === checkY,
				);
				if (isOccupied || !isWithinBoard) {
					isValid = false;
				}
			}
		}),
	);
	return isValid;
}

/**
 * How many squares are filled
 */

export function squaresFilled(game: TGameInProgress): number {
	// how many squares are filled
	let squaresFilled = 0;
	game.setItems.forEach((setItem) => {
		squaresFilled += itemSizes[setItem.itemId];
	});
	return squaresFilled;
}

/**
 * Calculate pack percentage
 */

export function packPercentage(game: TGameInProgress): number {
	const level = data.levels[game.levelId];
	const filled = squaresFilled(game);
	const available = boardAvailableSquares[level.boardId].length;
	const percentage = Math.round((filled / available) * 1000) / 10;
	return percentage;
}

/**
 * Score a game
 */

export function getGameScores(
	game: TGameInProgress,
	timer: number,
): TLevelPlayed | undefined {
	const isSuccess = game.requiredItems.length === 0;

	if (!isSuccess) {
		return;
	}

	const filled = squaresFilled(game);

	const score = filled * 50 + timer * 10;

	const percentFilled = packPercentage(game);

	let stars = 0.5;
	if (percentFilled > 50) stars = 1;
	if (percentFilled > 70) stars = 1.5;
	if (percentFilled > 85) stars = 2;
	if (percentFilled > 95) stars = 2.5;
	if (percentFilled === 100) stars = 3;

	const perfectPack = percentFilled === 100;

	return { score, perfectPack, stars };
}

/**
 * Get distance between two points
 */

export function getDistance(point1: number[], point2: number[]): number {
	const dx = point2[0] - point1[0];
	const dy = point2[1] - point1[1];
	return Math.round(Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2)));
}

/**
 * Get closest grid references from a point
 * Uses sub-position within a grid square to decide which
 * direction the next grid square to check will be
 */

const pointsToCheck = [
	[0, 0],
	[0, 1],
	[0, 2],
	[1, 0],
	[1, 2],
	[2, 0],
	[2, 1],
	[2, 2],
];

export function getClosestGridReferences(
	point: number[],
	gridSize: number,
	level: TDataLevel,
): number[][] {
	const board = data.boards[level.boardId];

	// calculate grid reference of where the event happened
	const [x, y] = point;
	const x1 = Math.floor(x / gridSize);
	const y1 = Math.floor(y / gridSize);

	// calculate WHERE in the grid square the event happened
	const xInCenterSquare = x - x1 * gridSize;
	const yInCenterSquare = y - y1 * gridSize;
	const pointToCheckDistanceFrom = [
		xInCenterSquare + gridSize,
		yInCenterSquare + gridSize,
	];

	// calculate the distance of each surrounding square to where the event happened
	const half = gridSize / 2;
	const references = pointsToCheck.map(([cx, cy]) => {
		const center = [cx * gridSize + half, cy * gridSize + half];
		const distanceToPoint = getDistance(pointToCheckDistanceFrom, center);
		return {
			distanceToPoint,
			point: [cx, cy],
		};
	});

	// sort the array of references by the distance to get the closest coords
	const sortedByClosest = references.sort((a, b) => {
		return a.distanceToPoint === b.distanceToPoint
			? 0
			: a.distanceToPoint < b.distanceToPoint
			? -1
			: 1;
	});

	// convert the offseted coordinates back to where the original event happened
	const convertedToGridReferences = sortedByClosest.map((d) => {
		const [offsetX, offsetY] = d.point;
		return [x1 - 1 + offsetX, y1 - 1 + offsetY];
	});
	const withCenter = [[x1, y1], ...convertedToGridReferences];

	// remove any grid references that are outside the grid
	const offgridRemoved = withCenter.filter((coords) => {
		if (
			coords[0] > board[0].length - 1 ||
			coords[0] < 0 ||
			coords[1] > board.length - 1 ||
			coords[1] < 0
		) {
			return false;
		}
		return true;
	});

	return offgridRemoved;
}

/**
 * How many squares are filled
 */

export function getCompletedEmojiGameGrid(game: TGameInProgress): number[][] {
	const boardId = data.levels[game.levelId].boardId;

	const filledCharacter = 128307;
	const emptyCharacter = 11036;
	const solidBootCharacter = 11035; //aka wall

	//set solid and not solid squares
	const board: any[][] = data.boards[boardId].map((row) =>
		row.map((value) =>
			value === 1
				? String.fromCodePoint(solidBootCharacter)
				: String.fromCodePoint(emptyCharacter),
		),
	);

	//set filled not filled squares
	game.setItems.forEach((setItem) => {
		const shape = shapeRotations[setItem.itemId][setItem.rotation];
		const [itemX, itemY] = setItem.location;
		shape.forEach((row, rowIndex) =>
			row.forEach((cell, colIndex) => {
				const x = colIndex + itemX;
				const y = rowIndex + itemY;
				if (cell === 1) {
					board[y][x] = String.fromCodePoint(filledCharacter);
				}
			}),
		);
	});

	return board;
}
