import classNames from 'classnames';
import React from 'react';
import { MobileScreenWrapper } from 'src/components/atoms/mobile-screen-wrapper';
import { EScreen, useScreen } from 'src/context/screen';
import data from 'src/components/screens/game/data';
import { TDataLevel } from 'src/components/screens/game/data/types';
import { GameEngine } from '../game/engine';
import styles from './index.module.scss';
import { createGame } from '../game/helpers';
import { itemsByComplexity } from '../game/data/computed';
import { DrawItemImage } from '../game/engine-draw';

type TLevelEditorScreen = 'Editing' | 'Previewing' | 'Copying';

export const LevelEditorScreen: React.FC = () => {
	const [, setScreen] = useScreen();
	const [level, setLevel] = React.useState<TDataLevel>({
		title: '',
		initialItems: ['angle4'],
		timeLimit: 90,
		boardId: 'default',
		presetItems: [],
		randomItemWeights: [10, 5, 4, 2, 1],
	});
	const [editorScreen, setEditorScreen] =
		React.useState<TLevelEditorScreen>('Editing');
	const setItems = (newItems: string[]): void => {
		setLevel((current) => ({
			...current,
			availableItems: newItems,
		}));
	};

	const textareaRef = React.createRef<HTMLTextAreaElement>();
	React.useEffect(() => {
		if (textareaRef.current && editorScreen === 'Copying') {
			textareaRef.current.select();
		}
	}, [textareaRef, editorScreen]);
	const handleTextareaChange = (
		e: React.ChangeEvent<HTMLTextAreaElement>,
	): void => {
		try {
			// this is only intended to be used internally so pm;y very simple data sanitation
			// if using publically this would need to be significantly beefed up
			const newLevel = JSON.parse(e.target.value) as TDataLevel;
			if (
				typeof newLevel.boardId === 'string' &&
				typeof newLevel.timeLimit === 'number' &&
				typeof newLevel.title === 'string' &&
				typeof newLevel.initialItems === 'object'
			) {
				setLevel(newLevel);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const [game, setGame] = React.useState(() => createGame(level, 'test'));
	React.useEffect(() => {
		setGame(createGame(level, 'test'));
	}, [level]);

	return (
		<MobileScreenWrapper>
			<h1>Level Editor</h1>
			<button onClick={() => setScreen(EScreen.Home)}>
				Go back home
			</button>
			<br />
			<br />
			<p>
				When finished, copy the output JSON from the "Data" tab and save
				it. You can paste it back in to replay or test the config.
				Refreshing the page will <b>NOT</b> save anything.
			</p>
			<div className={styles.tabs}>
				<button
					onClick={() => setEditorScreen('Editing')}
					className={
						editorScreen === 'Editing' ? styles.tabsActive : ''
					}
				>
					Edit
				</button>
				<button
					onClick={() => setEditorScreen('Previewing')}
					className={
						editorScreen === 'Previewing' ? styles.tabsActive : ''
					}
				>
					Preview
				</button>
				<button
					onClick={() => setEditorScreen('Copying')}
					className={
						editorScreen === 'Copying' ? styles.tabsActive : ''
					}
				>
					Data
				</button>
			</div>
			<hr />
			{editorScreen === 'Editing' && (
				<>
					<h2>Edit current config</h2>
					<div className={styles.form}>
						<h3>Title</h3>
						<input
							type="text"
							value={level.title}
							onChange={(e) =>
								setLevel((current) => ({
									...current,
									title: e.target.value,
								}))
							}
						/>
						<h3>Time limit (seconds)</h3>
						<input
							type="number"
							value={level.timeLimit}
							onChange={(e) =>
								setLevel((current) => ({
									...current,
									timeLimit: parseInt(e.target.value),
								}))
							}
						/>

						<h3>Item list</h3>
						<div className={styles.itemList}>
							{level.initialItems?.map((id, i) => (
								<div
									key={`${i}-${id}`}
									className={styles.itemListItem}
								>
									<div>
										<Blocks arr={data.items[id].shape} />
									</div>
									<button
										onClick={() => {
											const newItems =
												changeValuePosition(
													[
														...(level.initialItems ||
															[]),
													],
													i,
													Math.max(i - 1, 0),
												);
											setItems(newItems);
										}}
									>
										↑
									</button>
									<button
										onClick={() => {
											const newItems =
												changeValuePosition(
													[
														...(level.initialItems ||
															[]),
													],
													i,
													Math.min(
														i + 1,
														(
															level.initialItems ||
															[]
														).length - 1,
													),
												);
											setItems(newItems);
										}}
									>
										↓
									</button>
									<button
										onClick={() => {
											const newItems = [
												...(level.initialItems || []),
											];
											newItems.splice(i, 1);
											setItems(newItems);
										}}
									>
										X
									</button>
								</div>
							))}
						</div>

						<p>Tap item shape to add it to the list</p>
						<div className={styles.shapes}>
							{Object.entries(data.items).map(([id, item]) => (
								<button
									key={id}
									onClick={() => {
										setItems([
											...(level.initialItems || []),
											id,
										]);
									}}
								>
									<Blocks arr={item.shape} />
								</button>
							))}
						</div>
					</div>
					<hr />
					<h2>Board Shape</h2>
					<div className={styles.board}>
						{Object.entries(data.boards).map(([id, board]) => (
							<Blocks key={id} arr={board} />
						))}
					</div>
					<h2>Items</h2>
					<div className={styles.itemsByComplexity}>
						{itemsByComplexity.map((itemArr, i) => (
							<React.Fragment key={i}>
								<h4>Complexity: {i}</h4>
								<div>
									{itemArr.map((itemId, i) => {
										return (
											<div key={i}>
												<DrawItemImage
													itemId={itemId}
													size={36}
												/>
											</div>
										);
									})}
								</div>
							</React.Fragment>
						))}
					</div>
				</>
			)}
			{editorScreen === 'Previewing' && (
				<>{game && <GameEngine game={game} setGame={setGame} />}</>
			)}
			{editorScreen === 'Copying' && (
				<>
					<textarea
						ref={textareaRef}
						className={styles.copying}
						value={JSON.stringify(level)}
						onChange={handleTextareaChange}
					/>
				</>
			)}
		</MobileScreenWrapper>
	);
};

type TBlocks = {
	arr: number[][];
};

const Blocks: React.FC<TBlocks> = ({ arr }) => {
	return (
		<div className={styles.blocks}>
			{arr.map((row, rowIndex) => (
				<div key={rowIndex} className={styles.blocksRow}>
					{row.map((onOff, colIndex) => (
						<div
							key={colIndex}
							className={classNames(
								styles.blocksCell,
								onOff && styles.blocksCellOn,
							)}
						></div>
					))}
				</div>
			))}
		</div>
	);
};

const changeValuePosition = (
	arr: string[],
	init: number,
	target: number,
): string[] => {
	[arr[init], arr[target]] = [arr[target], arr[init]];
	return arr;
};
