import React from 'react';
import { flatLevelIds } from 'src/components/screens/game/data/computed';
import { getLocalStorage, setLocalStorage } from 'src/utils/local-storage';

export type TLevelPlayed = {
	score: number;
	previousScore?: number;
	perfectPack: boolean;
	stars: number;
};

type TLevelProgress = { [key: string]: TLevelPlayed };

type TLevelProgressContext = [TLevelProgress, (value: TLevelProgress) => void];

const Context = React.createContext<TLevelProgressContext>([
	{},
	() => {
		return undefined;
	},
]);

export const LevelProgressProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [value, setValue] = React.useState<TLevelProgress>(() => {
		const storedValue = getLocalStorage('levelProgress') as
			| TLevelProgress
			| undefined;
		return storedValue || {};
	});
	const setValueLocal = (value: TLevelProgress): void => {
		setLocalStorage('levelProgress', value);
		setValue(value);
	};
	return (
		<Context.Provider value={[value, setValueLocal]}>
			{children}
		</Context.Provider>
	);
};

export const useLevelProgress = (): TLevelProgressContext => {
	return React.useContext(Context);
};
type TSetIndividualLevelProgress = (
	key: string,
	currentGameScores: TLevelPlayed,
) => void;
export const useSetIndividualLevelProgress =
	(): TSetIndividualLevelProgress => {
		const [levelProgress, setLevelProgress] = useLevelProgress();
		return (key, currentGameScores) => {
			const highScores: TLevelPlayed = levelProgress[key] || {};
			if (highScores.score !== undefined)
				highScores.previousScore = highScores.score;
			if (currentGameScores.score > (highScores?.score || 0))
				highScores.score = currentGameScores.score;
			if (currentGameScores.stars > (highScores?.stars || 0))
				highScores.stars = currentGameScores.stars;
			if (!highScores?.perfectPack)
				highScores.perfectPack = currentGameScores.perfectPack;
			setLevelProgress({ ...levelProgress, [key]: highScores });
		};
	};
