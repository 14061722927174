/**
 * Convert seconds to MM:SS
 */

export function formatTime(seconds: number): string {
	const minutes = Math.floor(seconds / 60);
	const secs = seconds - minutes * 60;
	const formattedMinutes =
		minutes < 100 ? `00${minutes}`.slice(-2) : String(minutes);
	const formattedSeconds = `00${secs}`.slice(-2);
	return `${formattedMinutes}:${formattedSeconds}`;
}
